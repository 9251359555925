@font-face {
  font-family: "Lexend Mega";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("./LexendMega-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("./Lexend-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("./Lexend-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Messapia";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("./Messapia-Bold.otf") format("opentype");
}